import { Network } from '@/models';
import type { Network as BackendNetwork } from '@/graphql/generatedTypesAndHooks';
import { normalizeNetworkEnumValues } from '@/utils';
import { Client } from '@hashgraph/sdk';
import { defineStore } from 'pinia';

export const useHederaClientStore = defineStore('hederaClientStore', () => {
  const getClient = async (network: Network | BackendNetwork): Promise<Client> => {
    return normalizeNetworkEnumValues(network) === Network.Mainnet ? Client.forMainnet() : Client.forTestnet();
  };

  return {
    getClient,
  };
});
