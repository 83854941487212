<script setup lang="ts">
import { HELP_CENTER_LINK } from '@/config';
import { ABOUT_SCREEN_ROUTE_NAME } from '@/router';
import { ref } from 'vue';
import {
  CreditCardFilled,
  HomeFilled,
  DashboardFilled,
  GiftFilled,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  WalletFilled,
  SettingFilled,
  InfoCircleOutlined,
  GlobalOutlined,
} from '@ant-design/icons-vue';
import { useNavigationHelper } from '@/functions';
import Logo from '@/components/Logo.vue';
import { Grid } from 'ant-design-vue';
import { useUserStore } from '@/stores';
import { storeToRefs } from 'pinia';

const collapsed = ref<boolean>(false);
const { matchedPaths } = useNavigationHelper();
const screens = Grid.useBreakpoint();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
</script>

<template>
  <a-layout-sider
    class="sidebar"
    breakpoint="lg"
    v-model:collapsed="collapsed"
    :width="236"
    :collapsible="screens.lg"
    :collapsedWidth="56"
  >
    <template #trigger>
      <MenuUnfoldOutlined v-if="collapsed" />
      <MenuFoldOutlined v-if="!collapsed" />
      <span v-if="!collapsed">{{ $gettext('Collapse') }}</span>
    </template>
    <div class="sidebar__logo" :class="{ 'sidebar__logo--collapsed': collapsed }">
      <Logo :collapsed="collapsed" />
    </div>
    <a-menu mode="inline" :selectedKeys="matchedPaths">
      <a-menu-item key="/home">
        <template #icon>
          <HomeFilled />
        </template>
        <router-link to="/home">{{ $gettext('Home') }}</router-link>
      </a-menu-item>
      <a-menu-item v-if="!screens.xs" key="/dashboard">
        <template #icon>
          <DashboardFilled />
        </template>
        <router-link to="/dashboard">{{ $gettext('Dashboard') }}</router-link>
      </a-menu-item>
      <a-menu-item key="/campaigns">
        <template #icon>
          <GiftFilled />
        </template>
        <router-link to="/campaigns">{{ $gettext('Campaigns') }}</router-link>
      </a-menu-item>
      <a-menu-item key="/token-studio">
        <template #icon>
          <GlobalOutlined />
        </template>
        <router-link to="/token-studio">{{ $gettext('Token Studio') }}</router-link>
      </a-menu-item>
      <a-menu-item key="/multi-sig">
        <template #icon>
          <WalletFilled />
        </template>
        <router-link to="/multi-sig">{{ $gettext('Multi-Sig') }}</router-link>
      </a-menu-item>
      <a-menu-item key="/billing" v-if="user.organization.monetization">
        <template #icon>
          <CreditCardFilled />
        </template>
        <router-link to="/billing">{{ $gettext('Billing') }}</router-link>
      </a-menu-item>
      <a-menu-item key="/settings">
        <template #icon>
          <SettingFilled />
        </template>
        <router-link to="/settings">{{ $gettext('Settings') }}</router-link>
      </a-menu-item>
    </a-menu>
    <div class="sidebar__emptySpace"></div>
    <div class="sidebar__help">
      <div class="sidebar__help--title">{{ $gettext('Need help?') }}</div>
      <div class="description sidebar__help--description">{{ $gettext('Please check our FAQ') }}</div>
      <a :href="HELP_CENTER_LINK" target="_blank">
        <a-button block type="default" class="sidebar__help--button" size="middle">
          {{ $gettext('GO TO HELP SECTION') }}
        </a-button>
      </a>
    </div>
    <div class="text-center">
      <router-link :to="{ name: ABOUT_SCREEN_ROUTE_NAME }">
        <a-button type="text" class="sidebar__aboutButton" size="large">
          <span class="sidebar__aboutImage">
            <InfoCircleOutlined />
          </span>
          <span class="sidebar__aboutButton--text">{{ $gettext('About Console') }}</span>
        </a-button>
      </router-link>
    </div>
  </a-layout-sider>
</template>

<style lang="less" scoped>
@import '@/assets/variables';

.sidebar {
  z-index: 1;

  & :deep(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
  }

  & :deep(.ant-menu-sub) {
    background: transparent;
  }

  &.ant-layout-sider-collapsed {
    .sidebar__help {
      display: none;
    }

    .sidebar__aboutButton--text {
      display: none;
    }

    .sidebar__aboutButton {
      padding: 0;
      background: transparent;
      border: 0;
      height: 32px;
      margin-left: 10px;
    }

    .sidebar__aboutImage {
      margin-right: 0;
      padding: 4px 8px;
    }
  }

  &__help {
    background: @blade-color-3;
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border-radius: 8px;

    &--title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    &--description {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &--button {
      margin-top: 8px;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 166.667% */
      text-transform: uppercase;
    }
  }

  &__button {
    height: 48px !important;
  }

  &__aboutButton {
    position: relative;
    left: -10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #666;
    line-height: 22px;
    border-radius: 8px;
    border-width: 0;
    text-align: left;

    &:hover {
      border-width: 0;
    }
  }

  &__aboutImage {
    border-radius: 4px;
    background: #f0f0f0;
    color: #666;
    padding: 2px 6px;
    margin-right: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }

  &__divider {
    margin: 0 !important;
  }

  &__emptySpace {
    height: 100%;
  }

  &__logo {
    margin-bottom: 6px;

    &--collapsed {
      margin-bottom: 24px;
    }
  }
}
</style>
