import type { Network } from '@/models';
import { ApiService } from '@/services/ApiService';
import { fromUnixTime } from 'date-fns';
import { SaucerSwapService } from '@/services/SaucerSwapService';

export type NetworkRate = {
  rate: number;
  exp: number;
};

export type RateData = {
  tokenId: string;
  hbarPrice: string;
  usdPrice: number;
  decimals: number;
};

export class RateService {
  private static getRatesPromiseMap: Map<Network, Promise<RateData[]>> = new Map();

  public static async getRates(network: Network): Promise<RateData[]> {
    if (this.getRatesPromiseMap.has(network)) {
      return this.getRatesPromiseMap.get(network);
    }

    this.getRatesPromiseMap.set(
      network,
      new Promise((resolve, reject) => {
        SaucerSwapService.getRates(network)
          .then(resolve)
          .catch(reject)
          .finally(() => this.getRatesPromiseMap.delete(network));
      })
    );

    return this.getRatesPromiseMap.get(network);
  }

  public static async getNetworkRate(network: Network): Promise<NetworkRate> {
    const { current_rate } = await ApiService.getExchangeRate(network);
    return {
      rate: current_rate.cent_equivalent / 100 / current_rate.hbar_equivalent,
      exp: fromUnixTime(current_rate.expiration_time).getTime(),
    };
  }
}
