import { DEFAULT_NETWORK } from '@/config';
import { WalletException } from '@/exception';
import { BladeWalletError, BladeConnector } from '@bladelabs/blade-web3.js';
import type { BladeSigner } from '@bladelabs/blade-web3.js';
import { ConnectorStrategy } from '@bladelabs/blade-web3.js/src/models/interfaces';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { Store } from 'pinia';
import { ErrorLogLvl, HbarTokenId, HederaNetworks, Network, WalletError } from '@/models';
import { asyncWithTimeout, stringToStatus } from '@/utils';
import {
  Status,
  AccountAllowanceApproveTransaction,
  Transaction,
  TransactionResponse,
  Hbar,
  HbarUnit,
} from '@hashgraph/sdk';
import type { TokenAmount } from '@/models';
import Long from 'long';
import { ExceptionHandler } from '@/services';
import { useStablecoinSDKConnectionStore } from '@/stores/StablecoinSDKConnectionStore';

interface State {
  signer: BladeSigner | null;
  availableAccounts: string[];
  network: Network;
  isInitializing: boolean;
}

interface Getters {
  accountId: string | null;
  hasSession: boolean;
}

interface Actions {
  changeAccount: (accountId: string) => Promise<void>;
  signTokensAllowance: (allowanceAmounts: TokenAmount[], allowanceAccountId: string) => Promise<Status>;
  signNftAllowance: (tokenIds: string[], allowanceAccountId: string) => Promise<Status>;
  processTransaction: (transaction: Transaction) => Promise<Status>;
  processTransactionWithReturnValue: (transaction: Transaction) => Promise<TransactionResponse>;
  signAndExecuteTransaction: (transaction: Transaction) => Promise<TransactionResponse>;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  executeTransaction: (transaction: Transaction) => Promise<TransactionResponse>;
  connectOrNotConnected: (targetNetwork?: Network) => Promise<boolean>;
  disconnect: () => Promise<void>;
  destroy: () => Promise<void>;
}

export type WalletConnectionStoreType = Store<'walletConnection', State, Getters, Actions>;

export const useWalletConnectionStore: () => WalletConnectionStoreType = defineStore('walletConnection', () => {
  const sdkConnectionStore = useStablecoinSDKConnectionStore();
  const signer = ref<BladeSigner | null>(null);
  const connector = ref<BladeConnector | null>(null);
  const availableAccounts = ref<string[]>([]);
  const network = ref<Network>(null);
  const isInitializing = ref<boolean>(false);

  const hasSession = computed(() => !!signer.value && !isInitializing.value);
  const accountId = computed(() => (hasSession.value ? signer.value.getAccountId().toString() : null));

  async function createConnector() {
    return await BladeConnector.init(ConnectorStrategy.AUTO, {
      name: 'Blade Console',
      url: window.location.origin,
      description: 'Blade Console is a decentralized crowdfunding platform',
      icons: [`${window.location.origin}/favicon.png`],
    });
  }

  async function initConnection(targetNetwork: Network = DEFAULT_NETWORK) {
    try {
      if (!hasSession.value || network.value !== targetNetwork) {
        setInitializing(true);
        network.value = targetNetwork;

        await sdkConnectionStore.disconnect();
        await killSession();

        connector.value = await createConnector();
        availableAccounts.value = await asyncWithTimeout(
          connector.value.createSession({ network: HederaNetworks[network.value] }),
          120000
        );

        await connector.value.onWalletUnlocked(() => setSigner(connector.value.getSigners()[0]));
        await connector.value.onWalletLocked(() => setSigner(null));
        connector.value.onSessionDisconnect(() => setSigner(null));
        connector.value.onSessionExpire(() => setSigner(null));
        setSigner(connector.value.getSigners()[0]);
      }
    } catch (err) {
      await handleError(err);
      throw err;
    } finally {
      setInitializing(false);
    }
  }

  async function changeAccount(accountId: string) {
    if (!hasSession.value) {
      return;
    }

    signer.value = connector.value.getSigners().find((signer) => signer.getAccountId().toString() === accountId);
  }

  async function signTokensAllowance(allowanceAmounts: TokenAmount[], allowanceAccountId: string): Promise<Status> {
    const tokens = allowanceAmounts.filter((ta) => ta.token.tokenId !== HbarTokenId);
    const hbar = allowanceAmounts.find((ta) => ta.token.tokenId === HbarTokenId);
    const transaction = new AccountAllowanceApproveTransaction();

    if (hbar) {
      transaction.approveHbarAllowance(
        accountId.value,
        allowanceAccountId,
        Hbar.fromString(hbar.amount, HbarUnit.Tinybar)
      );
    }

    tokens.forEach((ta) => {
      transaction.approveTokenAllowance(
        ta.token.tokenId,
        accountId.value,
        allowanceAccountId,
        Long.fromString(ta.amount)
      );
    });

    transaction.setTransactionMemo('Blade Console allowance transaction');

    return processTransaction(transaction);
  }

  async function signNftAllowance(tokenIds: [], allowanceAccountId: string): Promise<Status> {
    const transaction = new AccountAllowanceApproveTransaction();

    tokenIds.forEach((tokenId) =>
      transaction.approveTokenNftAllowanceAllSerials(tokenId, accountId.value, allowanceAccountId)
    );

    transaction.setTransactionMemo('Blade Console allowance transaction');

    return processTransaction(transaction);
  }

  async function signAndExecuteTransaction(transaction: Transaction): Promise<TransactionResponse> {
    try {
      const signedTx = await signer.value.signTransaction(transaction);
      return await signer.value.call(signedTx);
    } catch (e) {
      if (e?.error?.type === 'signature_rejected') {
        throw new WalletException('Transaction rejected by user.');
      }

      console.error('Error processing transaction: ', e);
      return null;
    }
  }

  async function signTransaction<T extends Transaction>(transaction: T): Promise<T> {
    try {
      return await signer.value.signTransaction(transaction);
    } catch (e) {
      console.error('Error processing transaction: ', e);
      return null;
    }
  }

  async function processTransaction<T extends Transaction>(transaction: T): Promise<Status> {
    let status;

    try {
      const populatedTx = await signer.value.populateTransaction(transaction);
      const signedTx = await signer.value.signTransaction(populatedTx.freeze());
      const txResponse = await signer.value.call(signedTx);
      const receipt = await signer.value.call(txResponse.getReceiptQuery());
      status = receipt.status;
    } catch (e) {
      status = stringToStatus(e.error?.status) || Status.Unknown;
      ExceptionHandler.handleError(e.error || e);
    }

    return status;
  }

  async function executeTransaction<T extends Transaction>(transaction: T): Promise<TransactionResponse> {
    return await signer.value.call(transaction);
  }

  async function processTransactionWithReturnValue(transaction: Transaction): Promise<TransactionResponse> {
    try {
      const populatedTx = await signer.value.populateTransaction(transaction);
      const signedTx = await signer.value.signTransaction(populatedTx.freeze());
      return await signer.value.call(signedTx);
    } catch (e) {
      ExceptionHandler.handleError(e.error || e);
      return null;
    }
  }

  async function killSession() {
    if (!connector.value) return;

    try {
      await connector.value.killSession();
    } catch (e) {
      // ignore
    }
  }

  async function disconnect() {
    if (!hasSession.value) {
      return;
    }

    await killSession();
    $reset();
  }

  async function destroy() {
    try {
      if (!connector.value) {
        connector.value = await createConnector();
      }

      await connector.value.killSession();
    } catch (e) {
      console.log(e);
      // ignore
    }

    $reset();
  }

  function setSigner(value: BladeSigner | null) {
    signer.value = value;
  }

  function setInitializing(value: boolean) {
    isInitializing.value = value;
  }

  async function handleError(error) {
    if (error.name === BladeWalletError.WalletConnectNotInitialized) {
      ExceptionHandler.handleError(
        new WalletError(error, 'WalletConnect is not initialized.', { logLvl: ErrorLogLvl.WARN })
      );
      $reset();
    } else if (error.message === `The user's wallet is locked.`) {
      ExceptionHandler.handleError(
        new WalletError(error, `The user's wallet is locked.`, { logLvl: ErrorLogLvl.WARN })
      );
      await killSession();
      $reset();
    } else if (error.message === 'Async call timeout limit reached.') {
      ExceptionHandler.handleError(new WalletError(error, 'Wallet connection lost.', { logLvl: ErrorLogLvl.WARN }));
      $reset();
    } else {
      ExceptionHandler.handleError(new WalletError(error));
      $reset();
    }
  }

  async function connectOrNotConnected(targetNetwork: Network = DEFAULT_NETWORK) {
    if (hasSession.value && targetNetwork === network.value) {
      return true;
    }

    try {
      await initConnection(targetNetwork);

      return true;
    } catch (e) {
      return false;
    }
  }

  function $reset() {
    signer.value = null;
    availableAccounts.value = [];
    isInitializing.value = false;
  }

  return {
    signer,
    availableAccounts,
    network,
    isInitializing,
    accountId,
    hasSession,
    changeAccount,
    signTokensAllowance,
    signNftAllowance,
    processTransaction,
    processTransactionWithReturnValue,
    connectOrNotConnected,
    signAndExecuteTransaction,
    executeTransaction,
    signTransaction,
    disconnect,
    destroy,
  };
});
