import { ExceptionHandler } from '@/services';
import { BldConsoleError, ErrorLogLvl } from '@/models';

export const asyncTimeout = (delay: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), delay);
  });
};

export const asyncWithTimeout = async <T>(promise: Promise<T>, timeLimit: number = 5000): Promise<T> => {
  let timeoutHandle;

  const timeoutPromise = new Promise((_, reject) => {
    timeoutHandle = setTimeout(() => reject(new Error('Async call timeout limit reached.')), timeLimit);
  });

  return Promise.race([promise, timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result as T;
  });
};

export const waitUntil = async (
  predicate: () => boolean | Promise<boolean>,
  delay: number = 200,
  maxRetries: number = 30
): Promise<void> => {
  for (let retries = 0; retries < maxRetries; retries++) {
    try {
      const result = await predicate();

      if (result) {
        break;
      }

      await asyncTimeout(delay);
    } catch (e) {
      const error = new BldConsoleError(e, 'Error waiting until condition is true', { logLvl: ErrorLogLvl.WARN });
      ExceptionHandler.handleError(error);
      await asyncTimeout(delay);
    }
  }
};
