import type { APIFees, Network, NftInfo, PublicKey, TokenType } from './';

export class Token {
  constructor(
    public tokenId: string,
    public network: Network,
    public name: string,
    public symbol: string,
    public decimals: string,
    public type: TokenType,
    public kycKey: PublicKey,
    public initialSupply?: number,
    public totalSupply?: number,
    public maxSupply?: number,
    public treasury?: string,
    public fees?: APIFees,
    public paused?: boolean,
    public deleted?: boolean,
    public logo?: string,
    public nftInfo?: NftInfo
  ) {}
}
