<script setup lang="ts">
import Header from '@/components/Header.vue';
import Logo from '@/components/Logo.vue';
import BackButton from '@/components/MasterWizard/BackButton.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();
const displayBackButton = computed(() => route.params.routing !== 'success');
</script>

<template>
  <a-layout class="layout masterWizardLayout">
    <Header>
      <template #logo><Logo :size="135" /></template>
    </Header>
    <a-layout-content class="masterWizardLayout__content">
      <RouterView />
    </a-layout-content>
    <a-layout-footer class="masterWizardLayout__footer">
      <BackButton v-if="displayBackButton" />
    </a-layout-footer>
  </a-layout>
</template>

<style lang="less" scoped>
.masterWizardLayout {
  height: 100%;

  &__content {
    display: flex;
    min-height: min-content;
    align-items: center;
    justify-content: center;
    background-color: inherit;
  }

  &__footer {
    background-color: inherit;
    width: 100%;
    text-align: center;
  }
}
</style>
