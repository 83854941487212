<script setup lang="ts">
import RequestResetPasswordForm from '@/components/forms/RequestResetPasswordForm/RequestResetPasswordForm.vue';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm/ResetPasswordForm.vue';
import type { ResetPasswordFormModel } from '@/components/forms/ResetPasswordForm/ResetPasswordFormModel';
import { useIsValidForm } from '@/functions';
import { ExceptionHandler, NotificationService } from '@/services';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGettext } from 'vue3-gettext';

const auth = getAuth();
const route = useRoute();
const router = useRouter();
const { $gettext } = useGettext();

const loading = ref(false);
const data = ref<ResetPasswordFormModel>({ password: '', confirmPassword: '' });
const formRef = ref<InstanceType<typeof RequestResetPasswordForm>>();
const isValidForm = useIsValidForm(formRef, data);

const submit = async () => {
  try {
    loading.value = true;
    await confirmPasswordReset(auth, route.query.oobCode as string, data.value.password);

    NotificationService.success($gettext('Password reset successfully!'));
    await router.push('/');
  } catch (e) {
    NotificationService.processError(e);
    ExceptionHandler.handleError(e);
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <a-row align="middle" justify="space-around" class="resetPasswordView">
    <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="6">
      <a-card :title="$gettext('Reset Password Confirmation')">
        <div class="description">
          {{ $gettext('Almost done! Enter your new password below to finish resetting and access your account.') }}
        </div>
        <br />
        <ResetPasswordForm ref="formRef" @enter="submit" v-model:value="data" />

        <div>
          <a-button :disabled="!isValidForm" :loading="loading" type="primary" @click="submit">
            {{ $gettext('Submit') }}
          </a-button>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<style scoped lang="less">
@import '@/assets/variables';

.resetPasswordView {
  height: calc(100vh - @header-height * 4);
}
</style>
