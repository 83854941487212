import HomeDetailBladeLink from '@/components/Home/HomeDetail/HomeDetailBladeLink.vue';
import HomeDetailLoyalty from '@/components/Home/HomeDetail/HomeDetailLoyalty.vue';
import HomeDetailMultiSig from '@/components/Home/HomeDetail/HomeDetailMultiSig.vue';
import HomeDetailNftDrop from '@/components/Home/HomeDetail/HomeDetailNftDrop.vue';
import HomeDetailSdk from '@/components/Home/HomeDetail/HomeDetailSdk.vue';
import HomeDetailTokenDrop from '@/components/Home/HomeDetail/HomeDetailTokenDrop.vue';
import HomeDetailTokenStudio from '@/components/Home/HomeDetail/HomeDetailTokenStudio.vue';
import HomeDetailWhiteLabel from '@/components/Home/HomeDetail/HomeDetailWhiteLabel.vue';
import HomeUseCaseKarate from '@/components/Home/HomeUseCase/HomeUseCaseKarate.vue';
import HomeUseCaseLoyalty from '@/components/Home/HomeUseCase/HomeUseCaseLoyalty.vue';
import HomeUseCaseSunDao from '@/components/Home/HomeUseCase/HomeUseCaseSunDao.vue';
import HomeUseCaseSwiss from '@/components/Home/HomeUseCase/HomeUseCaseSwiss.vue';
import { LAST_ACTIVITY_KEY } from '@/config';
import { GAEvents } from '@/GAEvents';
import FullWidthLayout from '@/layouts/FullWidthLayout.vue';
import SafeAnonymousLayout from '@/layouts/SafeAnonymousLayout.vue';
import WizardLayout from '@/layouts/WizardLayout.vue';
import { getCookie } from '@/utils';
import DropFailureView from '@/views/DropFailureView.vue';
import DropSuccessView from '@/views/DropSuccessView.vue';
import DisclaimerView from '@/views/FullWidthLayout/DisclaimerView.vue';
import RequestResetPasswordView from '@/views/FullWidthLayout/RequestResetPasswordView.vue';
import ResetPasswordView from '@/views/FullWidthLayout/ResetPasswordView.vue';
import UserInviteView from '@/views/FullWidthLayout/UserInviteView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import LoginView from '@/views/UserLayout/LoginView.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores';
import LogoutView from '@/views/LogoutView.vue';
import BasicLayout from '@/layouts/BasicLayout.vue';
import UserLayout from '@/layouts/UserLayout.vue';
import MasterWizardLayout from '@/layouts/MasterWizardLayout.vue';
import { User, UserRole } from '@/models';
import { Capability } from '@/graphql/generatedTypesAndHooks';
import CheckoutView from '@/views/FullWidthLayout/CheckoutView.vue';
import gettext from '@/gettext';

export const HOME_SCREEN_ROUTE_NAME = 'home';
export const DASHBOARD_SCREEN_ROUTE_NAME = 'dashboard';
export const CAMPAIGNS_SCREEN_ROUTE_NAME = 'campaigns';
export const TOKEN_STUDIO_SCREEN_ROUTE_NAME = 'token-studio';
export const TOKEN_STUDIO_WIZARD_SCREEN_ROUTE_NAME = 'token-studio-wizard';
export const SDK_SCREEN_ROUTE_NAME = 'sdk';
export const DAPP_SCREEN_ROUTE_NAME = 'dapp';
export const LOGIN_SCREEN_ROUTE_NAME = 'login';
export const LOGOUT_SCREEN_ROUTE_NAME = 'logout';
export const WIZARD_SCREEN_ROUTE_NAME = 'wizard';
export const SAFE_WIZARD_SCREEN_ROUTE_NAME = 'multi-sig-wizard';
export const SAFE_SIGN_SCREEN_ROUTE_NAME = 'multi-sig-sign';
export const SAFE_SCREEN_ROUTE_NAME = 'multi-sig-detail';
export const SAFE_DASHBOARD_SCREEN_ROUTE_NAME = 'multi-sig';
export const DROP_SUCCESS_SCREEN_ROUTE_NAME = 'drop-success';
export const DROP_FAILURE_SCREEN_ROUTE_NAME = 'drop-failure';
export const BILLING_SCREEN_ROUTE_NAME = 'billing';
export const SETTINGS_SCREEN_ROUTE_NAME = 'settings';
export const ABOUT_SCREEN_ROUTE_NAME = 'about';
export const NOT_FOUND_ROUTE_NAME = 'not-found';
export const DISCLAIMER_ROUTE_NAME = 'disclaimer';
export const CHECKOUT_ROUTE_NAME = 'checkout';
export const USER_INVITE_ROUTE_NAME = 'user-invite';
export const REQUEST_RESET_PASSWORD_ROUTE_NAME = 'request-reset-password';
export const RESET_PASSWORD_ROUTE_NAME = 'reset-password';
export const SIGN_UP_ROUTE_NAME = 'signup';
export const HOME_NFT_ROUTE_NAME = 'home-nft';
export const HOME_FT_ROUTE_NAME = 'home-ft';
export const HOME_BLADE_LINK_ROUTE_NAME = 'home-blade-link';
export const HOME_BLADE_LINK_SUN_DAO_ROUTE_NAME = 'home-blade-link-sun-dao';
export const HOME_WHITE_LABEL_ROUTE_NAME = 'home-white-label';
export const HOME_WHITE_LABEL_SWISS_ROUTE_NAME = 'home-white-label-swiss';
export const HOME_SDK_ROUTE_NAME = 'home-sdk';
export const HOME_SDK_KARATE_ROUTE_NAME = 'home-sdk-karate';
export const HOME_TOKEN_STUDIO_ROUTE_NAME = 'home-token-studio';
export const HOME_MULTI_SIGNATURE_ROUTE_NAME = 'home-multi-signature';
export const HOME_LOYALTY_ROUTE_NAME = 'home-loyalty';
export const HOME_LOYALTY_CARD_ROUTE_NAME = 'home-loyalty-card';

const { $gettext } = gettext;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    const scrollPosition = document.querySelector('#content_scroller')?.scrollTop;

    if (scrollPosition) {
      document.querySelector('#content_scroller').scrollTop = 0;
    }
  },
  routes: [
    {
      path: '/',
      component: BasicLayout,
      redirect: () => '/home',
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/home',
          name: HOME_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeView.vue'),
        },
        {
          path: '/home/nft',
          name: HOME_NFT_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('NFT Drop'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/nft', breadcrumbName: $gettext('NFT Drop') },
            ],
            component: HomeDetailNftDrop,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/ft',
          name: HOME_FT_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Fungible Token Drop'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/ft', breadcrumbName: $gettext('FT Drop') },
            ],
            component: HomeDetailTokenDrop,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/blade-link',
          name: HOME_BLADE_LINK_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('BladeLink: Customized Onboarding for Optimal Engagement'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/blade-link', breadcrumbName: $gettext('BladeLink') },
            ],
            component: HomeDetailBladeLink,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/blade-link/sun-dao',
          name: HOME_BLADE_LINK_SUN_DAO_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeUseCaseView.vue'),
          meta: {
            title: $gettext('BladeLink: SunDao'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/blade-link', breadcrumbName: $gettext('BladeLink') },
              { path: '/home/blade-link/sun-dao', breadcrumbName: $gettext('SunDao') },
            ],
            component: HomeUseCaseSunDao,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/white-label',
          name: HOME_WHITE_LABEL_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('White-Label Wallet Service'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/white-label', breadcrumbName: $gettext('White-Label Wallet') },
            ],
            component: HomeDetailWhiteLabel,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/white-label/swiss',
          name: HOME_WHITE_LABEL_SWISS_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeUseCaseView.vue'),
          meta: {
            title: $gettext('White Label Wallet: Swiss Blade'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/white-label', breadcrumbName: $gettext('White-Label Wallet') },
              { path: '/home/white-label/swiss', breadcrumbName: $gettext('Swiss Blade') },
            ],
            component: HomeUseCaseSwiss,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/sdk',
          name: HOME_SDK_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Blade SDK: Unleash Full-Spectrum Blockchain Capabilities'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/sdk', breadcrumbName: $gettext('Blade SDK') },
            ],
            component: HomeDetailSdk,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/sdk/karate',
          name: HOME_SDK_KARATE_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeUseCaseView.vue'),
          meta: {
            title: $gettext('Blade SDK: Karate Combat'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/sdk', breadcrumbName: $gettext('Blade SDK') },
              { path: '/home/sdk/karate', breadcrumbName: $gettext('Karate Combat') },
            ],
            component: HomeUseCaseKarate,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/token-studio',
          name: HOME_TOKEN_STUDIO_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Token Studio: Power Your Digital Asset Strategy'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/token-studio', breadcrumbName: $gettext('Token Studio') },
            ],
            component: HomeDetailTokenStudio,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/multi-signature',
          name: HOME_MULTI_SIGNATURE_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Multi-Signature Wallet: Empower Collaborative Security'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/multi-signature', breadcrumbName: $gettext('Multi-Signature Wallet') },
            ],
            component: HomeDetailMultiSig,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/loyalty',
          name: HOME_LOYALTY_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeDetailView.vue'),
          meta: {
            title: $gettext('Loyalty Card Drop: Next-Level Customer Engagement'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/loyalty', breadcrumbName: $gettext('Loyalty Card Drop') },
            ],
            component: HomeDetailLoyalty,
            sidebarKey: '/home',
          },
        },
        {
          path: '/home/loyalty/card',
          name: HOME_LOYALTY_CARD_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/home/HomeUseCaseView.vue'),
          meta: {
            title: $gettext('Loyalty Card Drop: Blade Wallet Loyalty Card'),
            breadcrumbs: [
              { path: '/', breadcrumbName: $gettext('Blade Console') },
              { path: '/home', breadcrumbName: $gettext('Home') },
              { path: '/home/loyalty', breadcrumbName: $gettext('Loyalty Card Drop') },
              { path: '/home/loyalty/card', breadcrumbName: $gettext('Blade Wallet Loyalty Card') },
            ],
            component: HomeUseCaseLoyalty,
            sidebarKey: '/home',
          },
        },
        {
          path: '/dashboard',
          name: DASHBOARD_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/DashboardView.vue'),
        },
        {
          path: '/campaigns',
          redirect: () => '/campaigns/overview',
        },
        {
          path: '/campaigns/:tab',
          name: CAMPAIGNS_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/DAppsView.vue'),
          meta: {
            sidebarKey: '/campaigns',
          },
        },
        {
          path: '/sdk/:code',
          name: SDK_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/EditSdkView.vue'),
          meta: {
            sidebarKey: '/campaigns',
            guard: (currentUser: User) =>
              currentUser.capabilities.includes(Capability.UpdateCampaign) ||
              currentUser.roles.includes(UserRole.SUPPORT_ADMIN),
          },
        },
        {
          path: '/dapp/:code',
          name: DAPP_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/EditDappView.vue'),
          meta: {
            sidebarKey: '/campaigns',
            guard: (currentUser: User) =>
              currentUser.capabilities.includes(Capability.UpdateCampaign) ||
              currentUser.roles.includes(UserRole.SUPPORT_ADMIN),
          },
        },
        {
          path: '/token-studio',
          name: TOKEN_STUDIO_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/TokenStudioView.vue'),
        },
        {
          path: '/multi-sig/:id',
          name: SAFE_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/SafeView.vue'),
          meta: {
            sidebarKey: '/multi-sig',
          },
        },
        {
          path: '/multi-sig',
          name: SAFE_DASHBOARD_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/DashboardSafeView.vue'),
        },
        {
          path: '/billing',
          redirect: () => '/billing/overview',
        },
        {
          path: '/billing/:tab',
          name: BILLING_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/BillingView.vue'),
          meta: {
            sidebarKey: '/billing',
          },
        },
        {
          path: '/settings',
          redirect: () => '/settings/profile',
        },
        {
          path: '/settings/:tab',
          name: SETTINGS_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/SettingsView.vue'),
          meta: {
            sidebarKey: '/settings',
          },
        },
        {
          path: '/about',
          name: ABOUT_SCREEN_ROUTE_NAME,
          component: () => import('@/views/BasicLayout/AboutView.vue'),
        },
      ],
    },
    {
      path: '/',
      component: UserLayout,
      children: [
        {
          path: '/login',
          name: LOGIN_SCREEN_ROUTE_NAME,
          component: LoginView,
        },
        {
          path: '/signup',
          name: SIGN_UP_ROUTE_NAME,
          component: LoginView,
        },
      ],
    },
    {
      path: '/',
      component: WizardLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: SAFE_WIZARD_SCREEN_ROUTE_NAME,
          path: '/multi-sig/wizard',
          component: () => import('@/views/WizardLayout/SafeWizardView.vue'),
        },
        {
          name: TOKEN_STUDIO_WIZARD_SCREEN_ROUTE_NAME,
          path: '/token-studio/wizard',
          component: () => import('@/views/WizardLayout/TokenStudioWizardView.vue'),
        },
      ],
    },
    {
      path: '/',
      component: MasterWizardLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: WIZARD_SCREEN_ROUTE_NAME,
          path: '/wizard',
          component: () => import('@/views/MasterWizardLayout/MasterWizardView.vue'),
          meta: {
            masterWizard: true,
            guard: (currentUser: User) => currentUser.capabilities.includes(Capability.CreateCampaign),
          },
        },
        {
          path: '/wizard/:routing(.*)',
          component: () => import('@/views/MasterWizardLayout/MasterWizardView.vue'),
          meta: {
            masterWizard: true,
            guard: (currentUser: User) => currentUser.capabilities.includes(Capability.CreateCampaign),
          },
        },
        {
          path: '/:code/continue-wizard',
          component: () => import('@/views/MasterWizardLayout/MasterWizardView.vue'),
          meta: {
            masterWizard: true,
            guard: (currentUser: User) =>
              currentUser.capabilities.includes(Capability.UpdateCampaign) ||
              currentUser.roles.includes(UserRole.SUPPORT_ADMIN),
          },
        },
        {
          path: '/:code/continue-wizard/:routing(.*)',
          component: () => import('@/views/MasterWizardLayout/MasterWizardView.vue'),
          meta: {
            masterWizard: true,
            guard: (currentUser: User) =>
              currentUser.capabilities.includes(Capability.UpdateCampaign) ||
              currentUser.roles.includes(UserRole.SUPPORT_ADMIN),
          },
        },
      ],
    },
    {
      path: '/',
      component: SafeAnonymousLayout,
      children: [
        {
          name: SAFE_SIGN_SCREEN_ROUTE_NAME,
          path: '/multi-sig/sign/:invite',
          component: () => import('@/views/SafeAnonymousLayout/SafeSignView.vue'),
        },
      ],
    },
    {
      path: '/',
      component: FullWidthLayout,
      children: [
        {
          path: '/disclaimer',
          name: DISCLAIMER_ROUTE_NAME,
          component: DisclaimerView,
        },
        {
          path: '/invite',
          name: USER_INVITE_ROUTE_NAME,
          component: UserInviteView,
        },
        {
          path: '/request-reset-password',
          name: REQUEST_RESET_PASSWORD_ROUTE_NAME,
          component: RequestResetPasswordView,
        },
        {
          path: '/reset-password',
          name: RESET_PASSWORD_ROUTE_NAME,
          component: ResetPasswordView,
        },
        {
          path: '/checkout/:sessionId',
          name: CHECKOUT_ROUTE_NAME,
          component: CheckoutView,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/logout',
      name: LOGOUT_SCREEN_ROUTE_NAME,
      component: LogoutView,
    },
    {
      path: '/drop/success',
      name: DROP_SUCCESS_SCREEN_ROUTE_NAME,
      component: DropSuccessView,
    },
    {
      path: '/drop/failure',
      name: DROP_FAILURE_SCREEN_ROUTE_NAME,
      component: DropFailureView,
    },
    {
      path: '/:pathMatch(.*)*',
      name: NOT_FOUND_ROUTE_NAME,
      component: NotFoundView,
    },
  ],
});

router.beforeEach(async (to, from) => {
  const { reloadUser, user } = useUserStore();
  let currentUser = user;

  if (to.meta.requiresAuth || to.meta.guard) {
    currentUser = currentUser || (await reloadUser());

    if (!currentUser || getCookie(LAST_ACTIVITY_KEY) === null) {
      return {
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      };
    }

    (window as any).gtag('event', GAEvents.ROUTE_CHANGE, {
      path: to.path,
      orgName: currentUser.organization?.name,
      orgId: currentUser.organization?.id,
      firebaseId: currentUser.firebaseId,
    });

    if (!currentUser?.accessToken) {
      return {
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      };
    }

    const guard = to.meta.guard as (user: User) => boolean;

    if (guard && !guard(currentUser)) {
      return from;
    }
  }
});

export default router;
