import { Network } from '@/models';
import { CampaignConfigNetworkEnum, Network as BackendNetwork } from '@/graphql/generatedTypesAndHooks';

export const normalizeNetworkEnumValues = (network: Network | BackendNetwork | CampaignConfigNetworkEnum): Network => {
  switch (network) {
    case Network.Testnet:
    case BackendNetwork.Testnet:
    case 'TESTNET':
      return Network.Testnet;
    case Network.Mainnet:
    case BackendNetwork.Mainnet:
    case 'MAINNET':
      return Network.Mainnet;
  }
};
