import gettext from '@/gettext';
import { passwordStrengthValidation } from '@/validation';
import type { Rule } from 'async-validator';
import type { Rule as AntRule } from 'ant-design-vue/es/form';

const { $gettext } = gettext;

export const passwordStrengthRule: Omit<Rule | AntRule, 'validator'> = {
  validator: (rule, value) => (passwordStrengthValidation(value) ? Promise.resolve() : Promise.reject()),
  message: $gettext(
    'Password should contain at least 1 lowercase and 1 uppercase character, 1 number and 1 special symbol.'
  ),
};
