import { Status, TokenId, TransactionId } from '@hashgraph/sdk';
import { camelCase, upperFirst } from 'lodash-es';
import { normalizeNetworkEnumValues } from '@/utils/EnumValues';
import type { Network } from '@/models';
import type { CampaignConfigNetworkEnum, Network as BackendNetwork } from '@/graphql/generatedTypesAndHooks';

export const normalizeTokenId = (text: string): TokenId | null => {
  try {
    return TokenId.fromString(text);
  } catch (e) {
    return null;
  }
};

export const stringToStatus = (statusString: string): Status => {
  if (!statusString) {
    return null;
  }

  const normalizedStatus = upperFirst(camelCase(statusString));

  return Status[normalizedStatus];
};

export const formatTxIdForURL = (transactionId: string): string | null => {
  try {
    const { accountId, validStart } = TransactionId.fromString(transactionId);
    const [shard, realm, num, sss, nnn] = [
      accountId.shard,
      accountId.realm,
      accountId.num,
      validStart.seconds,
      validStart.nanos,
    ].map((v) => v.toString());
    return `${shard}.${realm}.${num}-${sss}-${nnn}`;
  } catch (e) {
    return null;
  }
};

export const getHashScanUrl = (
  network: Network | BackendNetwork | CampaignConfigNetworkEnum,
  type: 'token' | 'transaction' | 'account' | 'contract',
  id: string
): string => {
  const normalizedNetwork = normalizeNetworkEnumValues(network);
  return `https://hashscan.io/${normalizedNetwork}/${type}/${id}`;
};
