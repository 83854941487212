import type { Network } from '@/models';
import { HederaMirrorNodes } from '@/models';
import axios, { Axios, AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

export class HederaClientFactory {
  private static clientsCache: Map<Network, Axios> = new Map();

  public static getClient(network: Network): Axios {
    if (!this.clientsCache.has(network)) {
      const client = axios.create({
        baseURL: HederaMirrorNodes[network],
        timeout: 10000,
      });

      axiosRetry(client, {
        retries: 3,
        retryDelay: (retryCount) => {
          return retryCount * 1000;
        },
        retryCondition: (error: AxiosError) => {
          if (!error?.response) {
            return true;
          }

          return error.response.status === 404;
        },
      });

      this.clientsCache.set(network, client);
    }

    return this.clientsCache.get(network);
  }
}
