import { BldConsoleError } from './BldConsoleError';
import type { ErrorOptions } from './BldConsoleError';
import { Status } from '@hashgraph/sdk';
import { stringToStatus } from '@/utils/HederaUtils';

export class TransactionError extends BldConsoleError {
  public status: Status;
  public transactionId: string;

  constructor(error?: Error, message?: string, options?: ErrorOptions) {
    super(error, message, options);
    this.status = stringToStatus((error as any)?.status) || Status.Unknown;
    this.transactionId = (error as any)?.transactionId || '';
  }
}
