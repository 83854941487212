<script setup lang="ts">
import { useUserStore } from '@/stores';
import { nextTick, onBeforeMount } from 'vue';
import { getAuth } from 'firebase/auth';
import { useRouter } from 'vue-router';

const auth = getAuth();
const userStore = useUserStore();
const router = useRouter();

onBeforeMount(async () => {
  await auth.signOut();
  userStore.$reset();
  await nextTick(async () => {
    await router.push('/login');
  });
});
</script>

<template>
  <div></div>
</template>
