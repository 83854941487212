import MintIcon from '/public/icons/mint.svg';
import DistributionIcon from '/public/icons/distribution.svg';
import PaidDropIcon from '/public/icons/paid_drop.svg';
import GatingIcon from '/public/icons/gating.svg';
import PaymasterIcon from '/public/icons/paymaster.svg';
import gettext from '@/gettext';
import type { Features } from '@/models';

const { $gettext } = gettext;

export const tokenDropFeatures: Features[] = [
  {
    name: $gettext('Token Mint'),
    description: $gettext('Easily create and manage branded tokens.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: MintIcon,
  },
  {
    name: $gettext('Drop Any Token'),
    description: $gettext('Distribute tokens seamlessly to your audience.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: DistributionIcon,
  },
  {
    name: $gettext('Amount Randomizer'),
    description: $gettext('Surprise users with dynamic token distributions.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: PaidDropIcon,
  },
  {
    name: $gettext('Gating'),
    description: $gettext('Ensure exclusive access with robust gating options.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: GatingIcon,
  },
  {
    name: $gettext('Paymaster'),
    description: $gettext('Facilitate hassle-free transactions by managing network fees.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: PaymasterIcon,
  },
  {
    name: $gettext('Paid drop'),
    isComingSoon: true,
    detailed: false,
    preview: true,
  },
];
