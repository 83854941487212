import { useCurrentUserOrganizationLazyQuery } from '@/graphql/generatedTypesAndHooks';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { FirebaseUser, User } from '@/models';
import { getCurrentUser } from 'vuefire';

export const useUserStore = defineStore('userStore', () => {
  const firebaseUser = ref<FirebaseUser | null>(null);
  const user = ref<User | null>(null);
  const isAnonymous = computed(() => !!firebaseUser?.value?.isAnonymous);
  const { refetch, load, onResult } = useCurrentUserOrganizationLazyQuery({});
  const isExpired = () => user.value?.exp < Date.now();
  const reloadUser = async (): Promise<User> => {
    firebaseUser.value = (await getCurrentUser()) as FirebaseUser;

    return firebaseUserToUser(firebaseUser.value as FirebaseUser);
  };

  let orgUserResolve = null;
  let orgUserPromise = null;

  const refreshToken = async () => {
    await firebaseUserToUser(firebaseUser.value as FirebaseUser, true);
  };

  function $reset() {
    firebaseUser.value = null;
    user.value = null;
  }

  onResult(({ data }) => {
    if (data?.currentUserOrganization) {
      user.value = {
        ...user.value,
        organization: {
          id: data.currentUserOrganization?.organization?.id,
          name: data.currentUserOrganization?.organization?.name,
          monetization: data.currentUserOrganization?.organization?.monetization,
        },
        capabilities: data.currentUserOrganization.capabilities,
      };

      orgUserResolve(user.value);
    }
  });

  const setDisplayName = (displayName: string) => {
    user.value.displayName = displayName;
  };

  const fetchOrganization = async () => {
    orgUserPromise = new Promise((resolve) => {
      orgUserResolve = resolve;
    });

    load() || refetch();

    return orgUserPromise;
  };

  const firebaseUserToUser = async (fbu: FirebaseUser, forceRefresh = false): Promise<User> => {
    if (!fbu) {
      return null;
    }

    const tokenInfo = await fbu.getIdTokenResult(forceRefresh);
    const roles = (tokenInfo?.claims?.roles || '').split(' ').filter((role) => !!role);

    user.value = {
      firebaseId: fbu.uid,
      displayName: fbu?.displayName || 'User',
      accessToken: tokenInfo?.token,
      exp: (fbu as any)?.stsTokenManager?.expirationTime || Date.now(),
      dapps: (tokenInfo?.claims?.dapps || '').split(' '),
      roles,
      organization: {
        id: user.value?.organization?.id || tokenInfo?.claims?.orgId || '',
        name: user.value?.organization?.name || tokenInfo?.claims?.orgName || '',
        monetization: user.value?.organization?.monetization || false,
      },
      capabilities: user.value?.capabilities,
    };

    await fetchOrganization();

    return user.value;
  };

  return {
    $reset,
    reloadUser,
    refreshToken,
    isExpired,
    fetchOrganization,
    setDisplayName,
    firebaseUser,
    user,
    isAnonymous,
    currentUser: user,
  };
});
