import type { Ref } from 'vue';
import { ref, watch } from 'vue';

export function useIsValidForm<FormType extends abstract new (...args: any) => any, FormValueType>(
  formRef: Ref<InstanceType<FormType>>,
  formValueRef: Ref<FormValueType>
): Ref<boolean> {
  const isFormValid = ref<boolean>(false);

  watch(
    [formRef, formValueRef],
    async () => {
      if (formRef.value && formRef.value?.validateForm) {
        isFormValid.value = (await formRef.value.validateForm()).valid;
      }
    },
    { deep: true, immediate: true }
  );

  return isFormValid;
}
