<script setup lang="ts">
import { LOGOUT_SCREEN_ROUTE_NAME } from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores';
import Avatar from '@/components/Avatar.vue';

const { currentUser } = storeToRefs(useUserStore());
</script>

<template>
  <span class="menuUser" v-if="currentUser">
    <a-dropdown trigger="click">
      <span class="menuUser__menu">
        <Avatar />
        <span class="hide-md" :key="currentUser.displayName">{{ currentUser.displayName }}</span>
      </span>
      <template #overlay>
        <a-menu>
          <a-menu-item>
            <router-link :to="{ name: LOGOUT_SCREEN_ROUTE_NAME }">
              <translate>{{ $gettext('Logout') }}</translate>
            </router-link>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </span>
</template>

<style lang="less">
.menuUser {
  &__displayName {
    padding-left: 8px;
  }

  &__menu {
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}
</style>
