import gettext from '@/gettext';
import type { Rule } from 'async-validator';
import type { Rule as AntRule } from 'ant-design-vue/es/form';
import BigNumber from 'bignumber.js';

const { $gettext } = gettext;

export const positiveAmountRule: Omit<Rule | AntRule, 'validator'> = {
  type: 'string',
  validator: (_, value) =>
    BigNumber(value || 0).isPositive() && !BigNumber(value || 0).isZero()
      ? Promise.resolve(true)
      : Promise.reject(false),
  message: $gettext('Must be a positive value.'),
};
