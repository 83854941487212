import MintIcon from '/public/icons/mint.svg';
import DistributionIcon from '/public/icons/distribution.svg';
import GatingIcon from '/public/icons/gating.svg';
import PaymasterIcon from '/public/icons/paymaster.svg';
import paidDropIcon from '/public/icons/paid_drop.svg';
import gettext from '@/gettext';
import type { Features } from '@/models';

const { $gettext } = gettext;

export const nftDropFeatures: Features[] = [
  {
    name: $gettext('Minting on Demand'),
    description: $gettext(
      'Efficient smart contract technology allows for immediate NFT creation when conditions are met, without the need for pre-minted tokens.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: MintIcon,
  },
  {
    name: $gettext('NFT Distribution'),
    description: $gettext('Facilitate the transfer of your existing NFT collection and ensure rightful ownership.'),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: DistributionIcon,
  },
  {
    name: $gettext('Gating'),
    description: $gettext(
      'Maintain exclusive access with advanced settings such as token verification, CAPTCHA integration, and device checks.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: GatingIcon,
  },
  {
    name: $gettext('Paymaster'),
    description: $gettext(
      'Handle network fees for your participants, ensuring a seamless and cost-free claiming process.'
    ),
    isComingSoon: false,
    detailed: true,
    preview: true,
    icon: PaymasterIcon,
  },
  {
    name: $gettext('Paid drop'),
    description: $gettext('Set your NFT drop pricing and provide a seamless buying experience.'),
    isComingSoon: true,
    detailed: true,
    preview: false,
    icon: paidDropIcon,
  },
  {
    name: $gettext('Paid drop'),
    isComingSoon: true,
    detailed: false,
    preview: true,
  },
];
