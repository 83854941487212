<script setup lang="ts">
import RequestResetPasswordForm from '@/components/forms/RequestResetPasswordForm/RequestResetPasswordForm.vue';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm/ResetPasswordForm.vue';
import type { ResetPasswordFormModel } from '@/components/forms/ResetPasswordForm/ResetPasswordFormModel';
import { useIsValidForm } from '@/functions';
import {
  type OobCodeQuery,
  useOobCodeLazyQuery,
  useRemoveInviteMutation,
  useValidateInviteQuery,
} from '@/graphql/generatedTypesAndHooks';
import { ExceptionHandler, NotificationService } from '@/services';
import { waitForApolloQuery } from '@/utils';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGettext } from 'vue3-gettext';

const auth = getAuth();
const route = useRoute();
const router = useRouter();
const { $gettext } = useGettext();

const loading = ref(false);
const isValidCode = ref(false);
const data = ref<ResetPasswordFormModel>({ password: '', confirmPassword: '' });
const formRef = ref<InstanceType<typeof RequestResetPasswordForm>>();
const { loading: validateLoading, onResult } = useValidateInviteQuery({ invite: route.query.code as string });
const { mutate } = useRemoveInviteMutation({});
const isValidForm = useIsValidForm(formRef, data);
const lazyQuery = useOobCodeLazyQuery({});

onResult((response) => {
  isValidCode.value = !!response.data?.validateInvite;
});

const submit = async () => {
  lazyQuery.load(null, { invite: route.query.code as string });
  const result = (await waitForApolloQuery(lazyQuery)) as OobCodeQuery;

  if (!result.oobCode) {
    NotificationService.error($gettext('Invalid invite code!'));
    return;
  }

  try {
    loading.value = true;

    await confirmPasswordReset(auth, result.oobCode as string, data.value.password);

    NotificationService.success($gettext('Password reset successfully!'));
    await mutate({ invite: route.query.code as string });
    await router.push('/');
  } catch (e) {
    NotificationService.processError(e);
    ExceptionHandler.handleError(e);
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <div class="userInviteView">
    <div v-if="validateLoading" class="userInviteView__loading content-padding">
      <a-spin size="large" />
    </div>
    <a-row
      v-else-if="!validateLoading && isValidCode"
      align="middle"
      justify="space-around"
      class="userInviteView__block"
    >
      <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="6">
        <a-card :title="$gettext('Set Your Password')">
          <div class="description">
            {{
              $gettext(
                `Welcome! You've been invited to set up your account password. Please fill in the form below to create your secure password and access your account.`
              )
            }}
          </div>
          <br />
          <ResetPasswordForm ref="formRef" @enter="submit" v-model:value="data" />

          <div>
            <a-button :disabled="!isValidForm" :loading="loading" type="primary" @click="submit">
              {{ $gettext('Submit') }}
            </a-button>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row v-else align="middle" justify="space-around" class="userInviteView__block">
      <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="6">
        <a-card>
          <div class="description text-center">
            {{
              $gettext(
                `Invalid invite code! Please make sure you have the correct link or contact the person who invited you.`
              )
            }}
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<style scoped lang="less">
@import '@/assets/variables';

.userInviteView {
  height: calc(100vh - @header-height * 4);

  &__block {
    height: 100%;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    height: 100%;
  }
}
</style>
