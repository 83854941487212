import { ContractCreateTransaction, ContractFunctionParameters, Hbar, Transaction } from '@hashgraph/sdk';
import type { Network } from '@/models';
import { AbiCoder, Interface } from '@ethersproject/abi';
import { ApiService } from '@/services/ApiService';
import { ExceptionHandler } from '@/services/ExceptionHandler';
import { Buffer } from 'buffer';

export class SmartContractService {
  public static createSmartContract(
    fileId: string,
    params: ContractFunctionParameters,
    gas: number,
    initialBalance?: Hbar
  ): Transaction {
    const transaction = new ContractCreateTransaction()
      .setBytecodeFileId(fileId)
      .setConstructorParameters(params)
      .setGas(gas);

    if (initialBalance != null && !initialBalance.toBigNumber().isZero()) {
      transaction.setInitialBalance(initialBalance);
    }

    return transaction;
  }

  public static async getContractNFTMetadata(network: Network, contractId: string): Promise<string> {
    const abi = new Interface(['function getMetadata()']);
    const data = abi.encodeFunctionData('getMetadata');

    try {
      const response = await ApiService.callContract(network, contractId, data);
      const result = response?.result || '';

      const [[hex]] = new AbiCoder().decode(['bytes[] memory'], result);
      return Buffer.from(hex.substring(2), 'hex').toString();
    } catch (e) {
      ExceptionHandler.handleError(e);

      return null;
    }
  }
}
