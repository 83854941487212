import { defineStore } from 'pinia';
import type { SubscriptionDto } from '@/graphql/generatedTypesAndHooks';
import { useCurrentSubscriptionQuery } from '@/graphql/generatedTypesAndHooks';
import { ref } from 'vue';

export const useSubscriptionStore = defineStore('subscriptionStore', () => {
  const { onResult, refetch } = useCurrentSubscriptionQuery();
  const currentSubscription = ref<SubscriptionDto>();

  onResult((result) => {
    currentSubscription.value = result.data?.currentSubscription;
  });

  return {
    currentSubscription,
    refetch,
  };
});
