import type {
  AppMetadata,
  InitializationData,
  NetworkResponse,
  SupportedWallets,
  WalletEvent,
  ProxyConfigurationViewModel,
} from '@hashgraph/stablecoin-npm-sdk';
import {
  SDK,
  Account,
  Role,
  Fees,
  Network,
  Factory,
  LoggerTransports,
  StableCoin,
  ReserveDataFeed,
  Proxy,
  CapabilitiesRequest,
  AssociateTokenRequest,
  BurnRequest,
  CashInRequest,
  ConnectRequest,
  CreateRequest,
  DeleteRequest,
  GetAccountBalanceRequest,
  GetListStableCoinRequest,
  GetStableCoinDetailsRequest,
  GetTokenManagerListRequest,
  InitializationRequest,
  PauseRequest,
  SetNetworkRequest,
  WipeRequest,
  KYCRequest,
  GetRolesRequest,
  StableCoinRole,
  GrantMultiRolesRequest,
  RevokeMultiRolesRequest,
  GetAccountsWithRolesRequest,
  IncreaseSupplierAllowanceRequest,
  DecreaseSupplierAllowanceRequest,
  ResetSupplierAllowanceRequest,
  GetSupplierAllowanceRequest,
  FreezeAccountRequest,
  RescueRequest,
  RescueHBARRequest,
  UpdateReserveAmountRequest,
  UpdateReserveAddressRequest,
  UpdateCustomFeesRequest,
  UpdateRequest,
  GetProxyConfigRequest,
  GetFactoryProxyConfigRequest,
  ChangeProxyOwnerRequest,
  ChangeFactoryProxyOwnerRequest,
  AcceptProxyOwnerRequest,
  AcceptFactoryProxyOwnerRequest,
  UpgradeImplementationRequest,
  UpgradeFactoryImplementationRequest,
  CheckSupplierLimitRequest,
} from '@hashgraph/stablecoin-npm-sdk';
import { HederaMirrorNodes, HederaRPCNodes, Network as HederaNetwork } from '@/models';
import type { ReqDataType } from '@/types/stablecoinSDK/ReqDataType';
import { STABLECOIN_FACTORY } from '@/config';

export const MAX_TOKEN_DECIMALS = 18; // limitation to have integer part in max supply value: 9.223...
export const RESERVE_DECIMALS = 2; // stablecoin-studio limitation
export const MAX_TOKEN_SUPPLY = '9223372036854775807';

export interface ProxyConfig {
  implementationAddress: string;
  owner: string;
  pendingOwner: string;
}

export class StablecoinSDKService {
  public static async init(
    meta: AppMetadata,
    events: Partial<WalletEvent>,
    network: HederaNetwork
  ): Promise<SupportedWallets[]> {
    SDK.appMetadata = meta;
    SDK.log = {
      level: 'ERROR',
      transports: new LoggerTransports.Console(),
    };

    const initReq = new InitializationRequest({
      events,
      network,
      mirrorNode: this.getNodeConfig(network).mirrorNode,
      rpcNode: this.getNodeConfig(network).rpcNode,
      configuration: {
        factoryAddress: STABLECOIN_FACTORY[network],
      },
    });

    return await Network.init(initReq);
  }

  public static async connectWallet(wallet: SupportedWallets, network: HederaNetwork): Promise<InitializationData> {
    const networkConfig = await this.setNetwork(network);
    const connectReq = new ConnectRequest({
      wallet,
      network,
      ...networkConfig,
    });

    return await Network.connect(connectReq);
  }

  public static async disconnectWallet(): Promise<boolean> {
    try {
      return await Network.disconnect();
    } catch (e) {
      // ignore
    }
  }

  public static async setNetwork(network: HederaNetwork): Promise<NetworkResponse> {
    const setNetworkReq = new SetNetworkRequest({
      environment: network,
      mirrorNode: this.getNodeConfig(network).mirrorNode,
      rpcNode: this.getNodeConfig(network).rpcNode,
    });

    return await Network.setNetwork(setNetworkReq);
  }

  public static async getStableCoins(accountId: string): Promise<string[]> {
    const req = new GetListStableCoinRequest({ account: { accountId } });
    return await Account.listStableCoins(req).then((data) => data.coins.map((coin) => coin.id));
  }

  public static async createStableCoin(data: ReqDataType<typeof CreateRequest>): Promise<string> {
    const req = new CreateRequest(data);
    const factoryId = await Network.getFactoryAddress();
    const tokenManagerListReq = new GetTokenManagerListRequest({ factoryId });
    const list = await Factory.getHederaTokenManagerList(tokenManagerListReq);
    req.hederaTokenManager = list[0].value;
    const createResponse = await StableCoin.create(req);
    return createResponse.coin.tokenId.value;
  }

  public static async getInfo(tokenId: string) {
    return await StableCoin.getInfo(new GetStableCoinDetailsRequest({ id: tokenId }));
  }

  public static async getCapabilities(accountId: string, tokenId: string) {
    // we use try catch wrapper to filter out usual tokens, capabilities() method throws error if not stablecoin
    try {
      return await StableCoin.capabilities(
        new CapabilitiesRequest({
          account: { accountId },
          tokenId,
        })
      );
    } catch (e) {
      return null;
    }
  }

  public static async associateToken(data: ReqDataType<typeof AssociateTokenRequest>): Promise<boolean> {
    return await StableCoin.associate(new AssociateTokenRequest(data));
  }

  public static async cashIn(data: ReqDataType<typeof CashInRequest>): Promise<boolean> {
    return await StableCoin.cashIn(new CashInRequest(data));
  }

  public static async burn(data: ReqDataType<typeof BurnRequest>): Promise<boolean> {
    return await StableCoin.burn(new BurnRequest(data));
  }

  public static async wipe(data: ReqDataType<typeof WipeRequest>): Promise<boolean> {
    return await StableCoin.wipe(new WipeRequest(data));
  }

  public static async getBalance(data: ReqDataType<typeof GetAccountBalanceRequest>) {
    return await StableCoin.getBalanceOf(new GetAccountBalanceRequest(data));
  }

  public static async pause(data: ReqDataType<typeof PauseRequest>): Promise<boolean> {
    return await StableCoin.pause(new PauseRequest(data));
  }

  public static async unPause(data: ReqDataType<typeof PauseRequest>): Promise<boolean> {
    return await StableCoin.unPause(new PauseRequest(data));
  }

  public static async delete(data: ReqDataType<typeof DeleteRequest>): Promise<boolean> {
    return await StableCoin.delete(new DeleteRequest(data));
  }

  public static async update(data: ReqDataType<typeof UpdateRequest>): Promise<boolean> {
    return await StableCoin.update(new UpdateRequest(data));
  }

  public static async grantKyc(data: ReqDataType<typeof KYCRequest>): Promise<boolean> {
    return await StableCoin.grantKyc(new KYCRequest(data));
  }

  public static async revokeKyc(data: ReqDataType<typeof KYCRequest>): Promise<boolean> {
    return await StableCoin.revokeKyc(new KYCRequest(data));
  }

  public static async checkKyc(data: ReqDataType<typeof KYCRequest>): Promise<boolean> {
    return await StableCoin.isAccountKYCGranted(new KYCRequest(data));
  }

  public static async getRoles(data: ReqDataType<typeof GetRolesRequest>): Promise<StableCoinRole[]> {
    // we use try catch wrapper to filter out usual tokens, getRoles() method throws error if not stablecoin
    try {
      const res = await Role.getRoles(new GetRolesRequest(data));
      return res.filter((v: string) => v !== StableCoinRole.WITHOUT_ROLE) as StableCoinRole[];
    } catch (e) {
      return [];
    }
  }

  public static async grantMultiRoles(data: ReqDataType<typeof GrantMultiRolesRequest>): Promise<boolean> {
    return await Role.grantMultiRoles(new GrantMultiRolesRequest(data));
  }

  public static async revokeMultiRoles(data: ReqDataType<typeof RevokeMultiRolesRequest>): Promise<boolean> {
    return await Role.revokeMultiRoles(new RevokeMultiRolesRequest(data));
  }

  public static async getAccountsWithRole(data: ReqDataType<typeof GetAccountsWithRolesRequest>): Promise<string[]> {
    return await Role.getAccountsWithRole(new GetAccountsWithRolesRequest(data));
  }

  public static async increaseAllowance(data: ReqDataType<typeof IncreaseSupplierAllowanceRequest>): Promise<boolean> {
    return await Role.increaseAllowance(new IncreaseSupplierAllowanceRequest(data));
  }

  public static async decreaseAllowance(data: ReqDataType<typeof DecreaseSupplierAllowanceRequest>): Promise<boolean> {
    return await Role.decreaseAllowance(new DecreaseSupplierAllowanceRequest(data));
  }

  public static async resetAllowance(data: ReqDataType<typeof ResetSupplierAllowanceRequest>): Promise<boolean> {
    return await Role.resetAllowance(new ResetSupplierAllowanceRequest(data));
  }

  public static async getAllowance(data: ReqDataType<typeof GetSupplierAllowanceRequest>): Promise<string> {
    const isUnlimited = await Role.isUnlimited(new CheckSupplierLimitRequest(data));

    if (isUnlimited) {
      return Infinity.toString();
    }

    const allowance = await Role.getAllowance(new GetSupplierAllowanceRequest(data));
    return allowance.value.toString();
  }

  public static async freeze(data: ReqDataType<typeof FreezeAccountRequest>): Promise<boolean> {
    return await StableCoin.freeze(new FreezeAccountRequest(data));
  }

  public static async unFreeze(data: ReqDataType<typeof FreezeAccountRequest>): Promise<boolean> {
    return await StableCoin.unFreeze(new FreezeAccountRequest(data));
  }

  public static async isAccountFrozen(data: ReqDataType<typeof FreezeAccountRequest>): Promise<boolean> {
    return await StableCoin.isAccountFrozen(new FreezeAccountRequest(data));
  }

  public static async rescue(data: ReqDataType<typeof RescueRequest>): Promise<boolean> {
    return await StableCoin.rescue(new RescueRequest(data));
  }

  public static async rescueHBAR(data: ReqDataType<typeof RescueHBARRequest>): Promise<boolean> {
    return await StableCoin.rescueHBAR(new RescueHBARRequest(data));
  }

  public static async updateReserveAmount(data: ReqDataType<typeof UpdateReserveAmountRequest>): Promise<boolean> {
    return await ReserveDataFeed.updateReserveAmount(new UpdateReserveAmountRequest(data));
  }

  public static async updateReserveAddress(data: ReqDataType<typeof UpdateReserveAddressRequest>): Promise<boolean> {
    return await StableCoin.updateReserveAddress(new UpdateReserveAddressRequest(data));
  }

  public static async updateCustomFees(data: ReqDataType<typeof UpdateCustomFeesRequest>) {
    return await Fees.updateCustomFees(new UpdateCustomFeesRequest(data));
  }

  public static async getProxyConfig(data: ReqDataType<typeof GetProxyConfigRequest>) {
    const proxyConfig = await Proxy.getProxyConfig(new GetProxyConfigRequest(data));
    return this.mapProxyConfig(proxyConfig);
  }

  public static async getFactoryProxyConfig() {
    const factoryId = await Network.getFactoryAddress();
    const factoryProxyConfig = await Proxy.getFactoryProxyConfig(new GetFactoryProxyConfigRequest({ factoryId }));
    return this.mapProxyConfig(factoryProxyConfig);
  }

  public static async changeProxyOwner(data: ReqDataType<typeof ChangeProxyOwnerRequest>) {
    return await Proxy.changeProxyOwner(new ChangeProxyOwnerRequest(data));
  }

  public static async changeFactoryProxyOwner(targetId: string) {
    const factoryId = await Network.getFactoryAddress();
    return await Proxy.changeFactoryProxyOwner(new ChangeFactoryProxyOwnerRequest({ factoryId, targetId }));
  }

  public static async acceptProxyOwner(data: ReqDataType<typeof AcceptProxyOwnerRequest>) {
    return await Proxy.acceptProxyOwner(new AcceptProxyOwnerRequest(data));
  }

  public static async acceptFactoryProxyOwner() {
    const factoryId = await Network.getFactoryAddress();
    return await Proxy.acceptFactoryProxyOwner(new AcceptFactoryProxyOwnerRequest({ factoryId }));
  }

  public static async upgradeProxyImplementation(data: ReqDataType<typeof UpgradeImplementationRequest>) {
    return await Proxy.upgradeImplementation(new UpgradeImplementationRequest(data));
  }

  public static async upgradeFactoryProxyImplementation(implementationAddress: string) {
    const factoryId = await Network.getFactoryAddress();
    return await Proxy.upgradeFactoryImplementation(
      new UpgradeFactoryImplementationRequest({ factoryId, implementationAddress })
    );
  }

  public static isSDKError(error: any): boolean {
    const sdkErrorCodes = Object.values(ErrorCode);
    return (
      (error?.errorCode && sdkErrorCodes.includes(error.errorCode)) ||
      (error?.stack && sdkErrorCodes.some((code) => error.stack?.includes(code)))
    );
  }

  public static getSDKErrorMessage(error: Error): string {
    const errors = error.message
      .split(':')
      .map((text) => text.trim())
      .filter((text) => text.includes(' '));
    return errors.length ? errors[0] : null;
  }

  private static getNodeConfig(network: HederaNetwork) {
    return {
      mirrorNode: {
        baseUrl: `${HederaMirrorNodes[network]}/api/v1`,
      },
      rpcNode: {
        baseUrl: `${HederaRPCNodes[network]}/api`,
      },
    };
  }

  private static mapProxyConfig(proxyConfig: ProxyConfigurationViewModel): ProxyConfig {
    return proxyConfig
      ? {
          implementationAddress: !proxyConfig.implementationAddress.isNull()
            ? proxyConfig.implementationAddress.value
            : null,
          owner: !proxyConfig.owner.isNull() ? proxyConfig.owner.value : null,
          pendingOwner: !proxyConfig.pendingOwner.isNull() ? proxyConfig.pendingOwner.value : null,
        }
      : null;
  }
}

enum ErrorCode {
  AccountIdInValid = '10001',
  PrivateKeyInvalid = '10002',
  PrivateKeyTypeInvalid = '10003',
  PublicKeyInvalid = '10004',
  ContractKeyInvalid = '10006',
  InvalidAmount = '10008',
  InvalidIdFormatHedera = '10009',
  InvalidContractId = '10014',
  InvalidType = '10015',
  InvalidLength = '10016',
  EmptyValue = '10017',
  InvalidRange = '10018',
  InvalidRole = '10019',
  InvalidSupplierType = '10020',
  InvalidValue = '10021',
  ValidationChecks = '10022',
  InvalidEvmAddress = '10023',
  InvalidRequest = '10024',
  AccountIdNotExists = '10026',
  AccountNotAssociatedToToken = '20001',
  MaxSupplyReached = '20002',
  RoleNotAssigned = '20003',
  OperationNotAllowed = '20004',
  InsufficientFunds = '20005',
  KYCNotEnabled = '20006',
  AccountNotKyc = '20007',
  AccountFreeze = '20008',
  ReceiptNotReceived = '30001',
  ContractNotFound = '30002',
  Unexpected = '30003',
  RuntimeError = '30004',
  InvalidResponse = '30005',
  NotFound = '30006',
  UnsupportedKeyType = '30007',
  InitializationError = '40001',
  PairingError = '40002',
  TransactionCheck = '40003',
  SigningError = '40004',
  TransactionError = '40005',
  DeplymentError = '40006',
  ProviderError = '40007',
  PairingRejected = '40008',
  BackendError = '40009',
}
