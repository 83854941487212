import { setCookie } from '@/utils';
import { useGettext } from 'vue3-gettext';
import { useIdle } from '@vueuse/core';
import { NotificationService } from '@/services';
import { watch } from 'vue';
import { IDLE_NOTIFICATION_TIMEOUT, IDLE_LOGOUT_TIMEOUT, LAST_ACTIVITY_KEY, LAST_ACTIVITY_VALUE } from '@/config';
import { LOGOUT_SCREEN_ROUTE_NAME } from '@/router';
import { useRoute, useRouter } from 'vue-router';

export function useInactivityHandler(): void {
  const { $gettext } = useGettext();
  const route = useRoute();
  const router = useRouter();
  const debounceTime = 5000;

  const { idle: setCookieDebounce } = useIdle(debounceTime);
  const { idle: idleNotification } = useIdle(IDLE_NOTIFICATION_TIMEOUT);
  const { idle: idleLogout } = useIdle(IDLE_LOGOUT_TIMEOUT);
  const key = 'INACTIVITY_NOTIFICATION_KEY';

  watch(setCookieDebounce, async (value) => {
    if (value && route.meta?.requiresAuth) {
      setCookie(LAST_ACTIVITY_KEY, LAST_ACTIVITY_VALUE, IDLE_LOGOUT_TIMEOUT - debounceTime);
    }
  });

  watch(idleNotification, (value) => {
    if (value && route.meta?.requiresAuth) {
      NotificationService.info($gettext("You'll be logged out soon due to inactivity"), {
        duration: null,
        closeIcon: '',
        key,
      });
    } else {
      NotificationService.close(key);
    }
  });

  watch(idleLogout, async (value) => {
    if (value && route.meta?.requiresAuth) {
      NotificationService.close(key);
      await router.push({ name: LOGOUT_SCREEN_ROUTE_NAME });
    }
  });
}
