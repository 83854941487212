import { AccountId, ContractFunctionParameters, Hbar, HbarUnit, PublicKey, TokenId, Transaction } from '@hashgraph/sdk';
import BigNumber from 'bignumber.js';
import { LAZY_NFT_MINTER_FILE_ID, LAZY_NFT_MINTER_GAS_AMOUNT } from '@/config';
import { SmartContractService } from '@/services/SmartContractService';
import type { KeyType, KeyValueType } from '@/models';
import { Interface } from '@ethersproject/abi';
import { floatToHbar, waitUntil } from '@/utils';
import {
  HTS_CONTRACT_CALL_MULTIPLIER,
  TOKEN_CREATE_USD,
  TOKEN_WITH_FEES_CREATE_USD,
} from '@/models/HederaFeesConstants';
import { ApiService } from '@/services/ApiService';
import type { Network } from '@/models';
import { Buffer } from 'buffer';
import { NftInfo } from '@/models';
import { useRateStore } from '@/stores';

export interface NftData {
  metadata: string;
  publicKey: string;
  keyValueType: KeyValueType;
  keyTypes: KeyType[];
  tokenName: string;
  tokenSymbol: string;
  tokenMemo: string;
  tokenMaxSupply: number;
  royaltyCollector: string;
  fixedFee: number;
  royaltyFee: number;
  fallbackFee: number;
}

export class NftService {
  public static async getNftInfo(
    tokenId: string | TokenId,
    network: Network,
    accountId?: string,
    serial?: string,
    contractId?: string
  ): Promise<NftInfo | null> {
    if (contractId) {
      return new NftInfo(
        tokenId.toString(),
        null,
        await SmartContractService.getContractNFTMetadata(network, contractId),
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    }

    const apiNfts = await ApiService.getNftInfo(tokenId.toString(), network, null, accountId, serial, 1);

    if (!apiNfts?.nfts?.length) return null;

    const apiNftInfo = apiNfts.nfts[0];

    return new NftInfo(
      apiNftInfo.token_id,
      null,
      Buffer.from(apiNftInfo.metadata, 'base64').toString(),
      apiNftInfo.serial_number,
      apiNftInfo.account_id,
      apiNftInfo.spender,
      apiNftInfo.delegating_spender,
      apiNftInfo.created_timestamp,
      apiNftInfo.modified_timestamp,
      apiNftInfo.deleted
    );
  }

  public static async createLazyNftMinterSmartContract(
    payerAccount: string,
    tokenData: NftData,
    network: Network
  ): Promise<Transaction> {
    const textEncoder = new TextEncoder();
    const rate = await useRateStore().getNetworkRate(network);

    const fees = [
      new Hbar(tokenData.fixedFee, HbarUnit.Hbar).toTinybars().toNumber(),
      tokenData.royaltyFee,
      new Hbar(tokenData.fallbackFee, HbarUnit.Hbar).toTinybars().toNumber(),
    ];
    const hasFees = fees.some((fee) => fee !== 0);
    const tokenCreateCostUsd = hasFees ? TOKEN_WITH_FEES_CREATE_USD : TOKEN_CREATE_USD;
    const tokenCreateCost = floatToHbar((tokenCreateCostUsd * HTS_CONTRACT_CALL_MULTIPLIER) / rate);

    const params = new ContractFunctionParameters()
      .addAddress(AccountId.fromString(payerAccount).toSolidityAddress())
      .addBytesArray([textEncoder.encode(tokenData.metadata)])
      .addBytes(PublicKey.fromString(tokenData.publicKey).toBytesRaw())
      .addUint8(tokenData.keyValueType)
      .addUint8Array(tokenData.keyTypes)
      .addString(tokenData.tokenName)
      .addString(tokenData.tokenSymbol)
      .addString(tokenData.tokenMemo)
      .addInt64(new BigNumber(tokenData.tokenMaxSupply))
      .addAddress(AccountId.fromString(tokenData.royaltyCollector).toSolidityAddress())
      .addInt64Array(fees);

    return SmartContractService.createSmartContract(
      LAZY_NFT_MINTER_FILE_ID[network],
      params,
      LAZY_NFT_MINTER_GAS_AMOUNT,
      tokenCreateCost
    );
  }

  public static async getTokenIdFromMirrorNode(contractId: string, network: Network): Promise<string> {
    let logs = [];
    await waitUntil(
      async () => {
        const response = await ApiService.getContractResultsLogs(network, contractId);

        if (response.logs?.length) {
          logs = response.logs;

          return true;
        }

        return false;
      },
      1000,
      30
    );

    return NftService.decodeLazyNftMinterTokenId(logs);
  }

  private static decodeLazyNftMinterTokenId(logs: { data: string; topics: string[] }[]): string {
    const log = logs.find((l) => l.data && l.data !== '0x');
    const abi = new Interface(['event TokenCreated(address tokenId)']);
    const decodedLog = abi.decodeEventLog('TokenCreated', log.data, log.topics);

    return TokenId.fromSolidityAddress(decodedLog.tokenId).toString();
  }
}
