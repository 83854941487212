import { HederaNetwork } from '@bladelabs/blade-web3.js';

export enum Network {
  Mainnet = 'mainnet',
  Testnet = 'testnet',
}

export const HederaNetworks = {
  [Network.Mainnet]: HederaNetwork.Mainnet,
  [Network.Testnet]: HederaNetwork.Testnet,
};

export const HederaMirrorNodes = {
  [Network.Mainnet]: 'https://mainnet-public.mirrornode.hedera.com',
  [Network.Testnet]: 'https://testnet.mirrornode.hedera.com',
};

export const HederaRPCNodes = {
  [Network.Mainnet]: 'https://mainnet.hashio.io',
  [Network.Testnet]: 'https://testnet.hashio.io',
};
