import { Network, Token, TokenType } from './';
import { DEFAULT_NETWORK } from '@/config';

export const HbarTokenId = '0.0.0';

export class Hbar extends Token {
  constructor(network: Network = DEFAULT_NETWORK) {
    super(
      HbarTokenId,
      network,
      'Hedera HBAR',
      'HBAR',
      '8',
      TokenType.Fungible,
      null,
      50000000000,
      50000000000,
      50000000000
    );
  }
}
