import type { Network } from '@/models';
import { defineStore, type Store } from 'pinia';
import { computed, ref } from 'vue';
import { ExceptionHandler, NotificationService, StablecoinSDKService } from '@/services';
import { type EventParameter, SupportedWallets, PublicKey } from '@hashgraph/stablecoin-npm-sdk';
import { DEFAULT_NETWORK } from '@/config';
import { useToggle } from '@vueuse/core/index';
import { useGettext } from 'vue3-gettext';
import { asyncWithTimeout, normalizeNetworkEnumValues } from '@/utils';
import { useWalletConnectionStore } from '@/stores/WalletConnectionStore';

interface State {
  accountId: string;
  publicKey: PublicKey;
  network: Network;
  isInitializing: boolean;
}

interface Getters {
  hasSession: boolean;
}

interface Actions {
  connectWallet: (network?: Network) => Promise<void>;
  disconnect: () => Promise<void>;
}

export type StablecoinSDKConnectionStoreType = Store<'stablecoinSDK', State, Getters, Actions>;

export const useStablecoinSDKConnectionStore: () => StablecoinSDKConnectionStoreType = defineStore(
  'stablecoinSDK',
  () => {
    const { $gettext } = useGettext();
    const walletConnectionStore = useWalletConnectionStore();

    const initializedNetworks = ref<{ [key in Network]?: SupportedWallets[] }>({});

    const accountId = ref<string>(null);
    const publicKey = ref<PublicKey>(null);
    const network = ref<Network>(null);

    const [isInitializing, toggleInitializing] = useToggle(false);
    const hasSession = computed(() => !!accountId.value && !isInitializing.value);

    const initSDK = async (network: Network = DEFAULT_NETWORK) => {
      initializedNetworks.value[network] = await StablecoinSDKService.init(
        {
          name: 'Blade Console',
          url: window.location.origin,
          description: 'Blade Console is a decentralized crowdfunding platform',
          icon: `${window.location.origin}/favicon.png`,
        },
        {
          walletPaired: onWalletPaired,
          walletDisconnect: onWalletDisconnect,
        },
        network
      );
    };

    const connectWallet = async (network: Network = DEFAULT_NETWORK) => {
      try {
        toggleInitializing(true);

        await walletConnectionStore.destroy();

        if (!initializedNetworks.value[network]) {
          await asyncWithTimeout(initSDK(network), 120000);
        }

        await asyncWithTimeout(StablecoinSDKService.connectWallet(SupportedWallets.BLADE, network), 120000);
      } catch (e) {
        ExceptionHandler.handleError(e);

        if (e.code === 1000) {
          NotificationService.error($gettext('User has rejected connection'));
        } else {
          NotificationService.error($gettext('Stablecoin SDK initialization error. Please try again.'));
        }
      } finally {
        toggleInitializing(false);
      }
    };

    const disconnect = async () => {
      await StablecoinSDKService.disconnectWallet();
    };

    const onWalletPaired = (event: EventParameter<'walletPaired'>) => {
      accountId.value = event.data.account.id.value;
      publicKey.value = event.data.account.publicKey;
      network.value = normalizeNetworkEnumValues(event.network.name as Network);
    };

    const onWalletDisconnect = () => $reset();

    const $reset = () => {
      accountId.value = null;
      publicKey.value = null;
      network.value = null;
    };

    return {
      accountId,
      publicKey,
      network,
      isInitializing,
      hasSession,
      connectWallet,
      disconnect,
    };
  }
);
