import { Network } from '@/models';

export const GRAPHQL_ENDPOINT: string =
  import.meta.env?.VITE_CONSOLE_SERVER_URL + '/graphql';
export const CONSOLE_SERVER_URL: string =
  import.meta.env?.VITE_CONSOLE_SERVER_URL;
export const DEFAULT_NETWORK: Network =
  import.meta.env?.VITE_APP_DEFAULT_NETWORK || Network.Testnet;
export const DAPPS_CONFIGURER_URL = import.meta.env?.VITE_DAPPS_CONFIGURER_URL || '';
export const BLADE_FEE_COLLECTOR_ACCOUNT = {
  [Network.Testnet]: import.meta.env?.VITE_APP_BLADE_FEE_COLLECTOR_ACCOUNT_TESTNET || '',
  [Network.Mainnet]: import.meta.env?.VITE_APP_BLADE_FEE_COLLECTOR_ACCOUNT_MAINNET || '',
};
export const LAZY_NFT_MINTER_FILE_ID = {
  [Network.Testnet]: import.meta.env?.VITE_APP_BLADE_LAZY_NFT_MINTER_FILE_ID_TESTNET || '',
  [Network.Mainnet]: import.meta.env?.VITE_APP_BLADE_LAZY_NFT_MINTER_FILE_ID_MAINNET || '',
};
export const LAZY_NFT_MINTER_GAS_AMOUNT =
  import.meta.env?.VITE_APP_BLADE_LAZY_NFT_MINTER_GAS_AMOUNT || '1000000';
export const TOKEN_ICON_HOST = import.meta.env?.VITE_APP_TOKEN_ICON_HOST || '';
export const SAUCER_SWAP_HOST = {
  [Network.Mainnet]: import.meta.env?.VITE_APP_SAUCER_SWAP_HOST_MAINNET || '',
  [Network.Testnet]: import.meta.env?.VITE_APP_SAUCER_SWAP_HOST_TESTNET || ''
};
export const SAUCER_SWAP_HBAR_TOKEN_ID = {
  [Network.Mainnet]: import.meta.env?.VITE_APP_SAUCER_SWAP_HBAR_TOKEN_ID_MAINNET || '',
  [Network.Testnet]: import.meta.env?.VITE_APP_SAUCER_SWAP_HBAR_TOKEN_ID_TESTNET || ''
};
export const DEEPLINK_URL = import.meta.env?.VITE_APP_DEEPLINK_URL || 'https://api.bladewallet.io/mobile/profile/attachAccount';
export const FIREBASE_DEEPLINK_URL = import.meta.env?.VITE_APP_FIREBASE_DEEPLINK_URL || 'https://link.bladewallet.io/?link=';
export const CHROME_WEB_STORE_URL = import.meta.env?.VITE_APP_CHROME_WEB_STORE_URL || 'https://chrome.google.com/webstore/detail/blade-new-hedera-wallet/abogmiocnneedmmepnohnhlijcjpcifd';
export const OPEN_API_URL = import.meta.env?.VITE_OPEN_API_URL || '';
export const FIREBASE_WEB_APP_CONFIG = JSON.parse(import.meta.env?.VITE_FIREBASE_WEB_APP_CONFIG) || {};
export const DAPPS_DISCONNECT_FLOW = false;
export const SAFE_DISCONNECT_FLOW = false;
export const HELP_CENTER_LINK: string = import.meta.env?.VITE_HELP_CENTER_LINK;
export const TERMS_OF_USE_LINK: string = import.meta.env?.VITE_TERMS_OF_USE_LINK;
export const CUSTOMER_SUPPORT_LINK: string = import.meta.env?.VITE_CUSTOMER_SUPPORT_LINK;
export const PRIVACY_POLICY_LINK: string = import.meta.env?.VITE_PRIVACY_POLICY_LINK;
export const COOKIE_POLICY_LINK: string = import.meta.env?.VITE_COOKIE_POLICY_LINK;
export const CONTACT_LINK: string = import.meta.env?.VITE_CONTACT_LINK;
export const BILLING_EMAIL: string = import.meta.env?.VITE_BILLING_EMAIL;
export const APP_VERSION: string = import.meta.env?.VITE_APP_VERSION || '1.0.0';
export const BUILD_ENV: string = import.meta.env?.VITE_BUILD_ENV || 'dev';
export const SENTRY_DSN: string = import.meta.env?.VITE_SENTRY_DSN || '';
export const APP_ENABLE_MONETIZATION: boolean = (import.meta.env?.VITE_APP_ENABLE_MONETIZATION && Boolean(parseInt(import.meta.env?.VITE_APP_ENABLE_MONETIZATION))) || false;
export const STABLECOIN_FACTORY = {
  [Network.Testnet]: import.meta.env?.VITE_APP_STABLECOIN_FACTORY_TESTNET || '',
  [Network.Mainnet]: import.meta.env?.VITE_APP_STABLECOIN_FACTORY_MAINNET || '',
};
export const IDLE_NOTIFICATION_TIMEOUT = parseInt(import.meta.env?.VITE_APP_IDLE_NOTIFICATION_TIMEOUT) || 840000; // 14min
export const IDLE_LOGOUT_TIMEOUT = parseInt(import.meta.env?.VITE_APP_IDLE_LOGOUT_TIMEOUT) || 900000; // 15min
export const JS_SDK_REPO_LINK: string = import.meta.env?.VITE_JS_SDK_REPO_LINK;
export const JS_SDK_DOC_LINK: string = import.meta.env?.VITE_JS_SDK_DOC_LINK;
export const SWIFT_SDK_REPO_LINK: string = import.meta.env?.VITE_SWIFT_SDK_REPO_LINK;
export const SWIFT_SDK_DOC_LINK: string = import.meta.env?.VITE_SWIFT_SDK_DOC_LINK;
export const KOTLIN_SDK_REPO_LINK: string = import.meta.env?.VITE_KOTLIN_SDK_REPO_LINK;
export const KOTLIN_SDK_DOC_LINK: string = import.meta.env?.VITE_KOTLIN_SDK_DOC_LINK;
export const KOTLIN_SDK_DEMO_LINK: string = import.meta.env?.VITE_KOTLIN_SDK_DEMO_LINK;
export const UNITY_SDK_REPO_LINK: string = import.meta.env?.VITE_UNITY_SDK_REPO_LINK;
export const UNITY_SDK_DOC_LINK: string = import.meta.env?.VITE_UNITY_SDK_DOC_LINK;
export const UNITY_SDK_DEMO_LINK: string = import.meta.env?.VITE_UNITY_SDK_DEMO_LINK;
export const C_SDK_REPO_LINK: string = import.meta.env?.VITE_C_SDK_REPO_LINK;
export const C_SDK_DOC_LINK: string = import.meta.env?.VITE_C_SDK_DOC_LINK;
export const C_SDK_DEMO_LINK: string = import.meta.env?.VITE_C_SDK_DEMO_LINK;
export const SDK_DOC: string = import.meta.env?.VITE_SDK_DOC;
export const CUSTOMIZED_BLADELINT_LINK: string = import.meta.env?.VITE_CUSTOMIZED_BLADELINT_LINK;
export const WHITE_LABEL_BOOK_LINK: string = import.meta.env?.VITE_WHITE_LABEL_BOOK_LINK;
export const GO_MARKET_BOOK_LINK: string = import.meta.env?.VITE_GO_MARKET_BOOK_LINK;
export const LAST_ACTIVITY_KEY = '2574248136';
export const LAST_ACTIVITY_VALUE = '7099020239';
